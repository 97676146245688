import { svgIcon } from "@bitwarden/components";

export const RegistrationExpiredLinkIcon = svgIcon`
<svg width="64" height="64" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8198_6255)">
        <path 
            class="tw-fill-primary-600"
            d="M2.70609 5.38455C3.71495 3.18341 5.95991 1.65214 8.56633 1.65214C12.1167 1.65214 14.9948 4.4927 14.9948 7.99672C14.9948 11.5007 12.1167 14.3413 8.56633 14.3413C5.90396 14.3413 3.61848 12.7438 2.64241 10.4652C2.53502 10.2144 2.24202 10.0971 1.98799 10.2031C1.73396 10.3091 1.61509 10.5983 1.72248 10.849C2.8493 13.4796 5.48864 15.327 8.56633 15.327C12.6683 15.327 15.9936 12.0451 15.9936 7.99672C15.9936 3.9483 12.6683 0.666412 8.56633 0.666412C5.41721 0.666412 2.72675 2.60033 1.64603 5.33002L0.858226 3.92917C0.734524 3.70921 0.455929 3.63117 0.235967 3.75487C0.0160044 3.87858 -0.0620299 4.15717 0.0616722 4.37713L1.35147 6.6706C1.47517 6.89056 1.75377 6.9686 1.97373 6.8449L4.2672 5.5551C4.48716 5.4314 4.5652 5.1528 4.44149 4.93284C4.31779 4.71288 4.0392 4.63484 3.81923 4.75854L2.70609 5.38455Z"
            fill="#175DDC" />
        <path
            class="tw-fill-primary-600"
            d="M10.7225 10.2926C10.6408 10.3474 10.5437 10.3767 10.4447 10.3767C10.318 10.3767 10.1962 10.3283 10.1041 10.2418L8.14837 8.41125C8.09985 8.36578 8.06123 8.31053 8.03449 8.24991C8.00775 8.18928 7.99389 8.12376 7.99389 8.05727V3.49271C7.99389 3.36314 8.04588 3.23896 8.13896 3.14704C8.23204 3.05512 8.3578 3.00378 8.48901 3.00378C8.62021 3.00378 8.74597 3.05561 8.83905 3.14704C8.93213 3.23847 8.98412 3.36314 8.98412 3.49271V7.84605L10.7853 9.53284C10.8571 9.60031 10.9071 9.68734 10.9284 9.78268C10.9497 9.87802 10.9413 9.97776 10.9047 10.0687C10.868 10.1596 10.8042 10.2379 10.7225 10.2926Z"
            fill="#175DDC" />
    </g>
    <defs>
        <clipPath id="clip0_8198_6255">
            <rect width="15.9952" height="15.9952" fill="white" />
        </clipPath>
    </defs>
</svg>`;
