<form [formGroup]="formGroup" [bitSubmit]="submit">
  <bit-dialog dialogSize="large">
    <span bitDialogTitle class="tw-font-semibold">
      {{ "addPaymentMethod" | i18n }}
    </span>
    <ng-container bitDialogContent>
      <app-select-payment-method [showAccountCredit]="false" />
    </ng-container>
    <ng-container bitDialogFooter>
      <button bitButton bitFormButton buttonType="primary" type="submit">
        {{ "submit" | i18n }}
      </button>
      <button bitButton buttonType="secondary" type="button" [bitDialogClose]="ResultType.Closed">
        {{ "cancel" | i18n }}
      </button>
    </ng-container>
  </bit-dialog>
</form>
