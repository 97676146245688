<form [bitSubmit]="submit" [formGroup]="formGroup">
  <bit-form-field>
    <bit-label>{{ "masterPass" | i18n }}</bit-label>
    <input
      type="password"
      formControlName="masterPassword"
      bitInput
      appAutofocus
      name="masterPassword"
      required
    />
    <button type="button" bitIconButton bitSuffix bitPasswordInputToggle></button>
    <bit-hint>{{ "loggedInAsEmailOn" | i18n: email : webVaultHostname }}</bit-hint>
  </bit-form-field>

  <hr />

  <div class="tw-flex tw-gap-2">
    <button type="submit" bitButton bitFormButton buttonType="primary" block>
      <i class="bwi bwi-unlock" aria-hidden="true"></i>
      {{ "unlock" | i18n }}
    </button>
    <button type="button" bitButton bitFormButton block (click)="logOut()">
      {{ "logOut" | i18n }}
    </button>
  </div>
</form>
