<form
  [formGroup]="formGroup"
  [bitSubmit]="submitAndClose"
  [appApiAction]="formPromise"
  autocomplete="off"
>
  <bit-dialog dialogSize="large">
    <span bitDialogTitle>
      {{ title }}
    </span>
    <span bitDialogContent *ngIf="send">
      <bit-callout *ngIf="disableSend">
        {{ "sendDisabledWarning" | i18n }}
      </bit-callout>
      <bit-callout *ngIf="!disableSend && disableHideEmail">
        {{ "sendOptionsPolicyInEffect" | i18n }}
        <ul class="tw-mb-0">
          <li>{{ "sendDisableHideEmailInEffect" | i18n }}</li>
        </ul>
      </bit-callout>
      <bit-form-field class="tw-w-1/2">
        <bit-label for="name">{{ "name" | i18n }}</bit-label>
        <input bitInput type="text" formControlName="name" />
        <bit-hint>{{ "sendNameDesc" | i18n }}</bit-hint>
      </bit-form-field>
      <div class="tw-flex" *ngIf="!editMode">
        <bit-radio-group formControlName="type">
          <bit-label>{{ "whatTypeOfSend" | i18n }}</bit-label>

          <bit-radio-button
            *ngFor="let o of typeOptions"
            id="type_{{ o.value }}"
            class="tw-block"
            [value]="o.value"
            [disabled]="!canAccessPremium && o.premium"
          >
            <bit-label>
              {{ o.name }}
              <app-premium-badge
                class="tw-mx-1"
                *ngIf="!canAccessPremium && o.premium"
                slot="end"
              ></app-premium-badge>
            </bit-label>
          </bit-radio-button>
        </bit-radio-group>
      </div>
      <!-- Text -->
      <ng-container *ngIf="type === sendType.Text">
        <bit-form-field>
          <bit-label for="text">{{ "sendTypeText" | i18n }}</bit-label>
          <textarea bitInput id="text" rows="6" formControlName="text"></textarea>
          <bit-hint>{{ "sendTextDesc" | i18n }}</bit-hint>
        </bit-form-field>
        <bit-form-control>
          <input bitCheckbox type="checkbox" formControlName="textHidden" />
          <bit-label>{{ "textHiddenByDefault" | i18n }}</bit-label>
        </bit-form-control>
      </ng-container>
      <!-- File -->
      <ng-container *ngIf="type === sendType.File">
        <div class="tw-flex">
          <div *ngIf="editMode">
            <bit-label>{{ "file" | i18n }}</bit-label>
            <p bitTypography="body1" class="tw-mb-0">
              {{ send.file.fileName }} ({{ send.file.sizeName }})
            </p>
          </div>
          <bit-form-field *ngIf="!editMode">
            <bit-label>{{ "file" | i18n }}</bit-label>
            <div>
              <button bitButton type="button" buttonType="secondary" (click)="fileSelector.click()">
                {{ "chooseFile" | i18n }}
              </button>
              {{ selectedFile?.name ?? ("noFileChosen" | i18n) }}
            </div>
            <input
              bitInput
              #fileSelector
              type="file"
              id="file"
              name="file"
              formControlName="file"
              (change)="setSelectedFile($event)"
              hidden
              class="tw-hidden"
            />
            <bit-hint>{{ "sendFileDesc" | i18n }} {{ "maxFileSize" | i18n }}</bit-hint>
          </bit-form-field>
        </div>
      </ng-container>
      <h4 bitTypography="h4" class="tw-mt-5">{{ "share" | i18n }}</h4>

      <bit-form-field *ngIf="link">
        <bit-label for="link">{{ "sendLinkLabel" | i18n }}</bit-label>
        <input bitInput type="text" readonly formControlName="link" />
      </bit-form-field>

      <bit-form-control>
        <input bitCheckbox type="checkbox" formControlName="copyLink" />
        <bit-label>{{ "copySendLinkOnSave" | i18n }}</bit-label>
      </bit-form-control>
      <div class="tw-mt-5 tw-flex" (click)="toggleOptions()">
        <h4 bitTypography="h4" class="tw-mb-0 tw-mr-2">
          <button type="button" bitLink appStopClick [attr.aria-expanded]="showOptions">
            <i
              class="bwi"
              aria-hidden="true"
              [ngClass]="{ 'bwi-angle-right': !showOptions, 'bwi-angle-down': showOptions }"
            ></i>
            {{ "options" | i18n }}
          </button>
        </h4>
      </div>
      <div id="options" [hidden]="!showOptions">
        <div class="tw-flex">
          <div *ngIf="!editMode" class="tw-w-1/2 tw-pr-3">
            <bit-form-field>
              <bit-label for="deletionDate">{{ "deletionDate" | i18n }}</bit-label>
              <bit-select
                id="deletionDate"
                name="SelectedDeletionDatePreset"
                formControlName="selectedDeletionDatePreset"
              >
                <bit-option
                  *ngFor="let o of deletionDatePresets"
                  [value]="o.value"
                  [label]="o.name"
                ></bit-option>
              </bit-select>
              <ng-container *ngIf="formGroup.controls['selectedDeletionDatePreset'].value === 0">
                <input
                  bitInput
                  id="deletionDateCustom"
                  type="datetime-local"
                  name="DeletionDate"
                  formControlName="defaultDeletionDateTime"
                  placeholder="MM/DD/YYYY HH:MM AM/PM"
                />
              </ng-container>
              <bit-hint>{{ "deletionDateDesc" | i18n }}</bit-hint>
            </bit-form-field>
          </div>
          <div *ngIf="editMode" class="tw-w-1/2 tw-pr-3">
            <bit-form-field>
              <bit-label for="deletionDate">{{ "deletionDate" | i18n }}</bit-label>
              <input
                bitInput
                id="deletionDateCustom"
                type="datetime-local"
                name="DeletionDate"
                formControlName="defaultDeletionDateTime"
                placeholder="MM/DD/YYYY HH:MM AM/PM"
              />
              <bit-hint>{{ "deletionDateDesc" | i18n }}</bit-hint>
            </bit-form-field>
          </div>
          <div *ngIf="!editMode" class="tw-w-1/2 tw-pl-3">
            <bit-form-field>
              <bit-label for="expirationDate">
                {{ "expirationDate" | i18n }}
              </bit-label>
              <bit-select
                bitInput
                id="expirationDate"
                name="SelectedExpirationDatePreset"
                formControlName="selectedExpirationDatePreset"
              >
                <bit-option
                  *ngFor="let e of expirationDatePresets"
                  [value]="e.value"
                  [label]="e.name"
                ></bit-option>
              </bit-select>
              <ng-container *ngIf="formGroup.controls['selectedExpirationDatePreset'].value === 0">
                <input
                  bitInput
                  id="expirationDateCustom"
                  type="datetime-local"
                  name="ExpirationDate"
                  formControlName="defaultExpirationDateTime"
                  placeholder="MM/DD/YYYY HH:MM AM/PM"
                />
              </ng-container>
              <bit-hint>{{ "expirationDateDesc" | i18n }}</bit-hint>
            </bit-form-field>
          </div>
          <div *ngIf="editMode" class="tw-w-1/2 tw-pl-3">
            <bit-form-field>
              <bit-label class="tw-flex" for="expirationDate">
                {{ "expirationDate" | i18n }}

                <button
                  type="button"
                  bitLink
                  appStopClick
                  (click)="clearExpiration()"
                  *ngIf="!disableSend"
                  class="tw-ml-auto"
                  slot="end"
                >
                  {{ "clear" | i18n }}
                </button>
              </bit-label>
              <input
                bitInput
                id="expirationDateCustom"
                type="datetime-local"
                name="ExpirationDate"
                formControlName="defaultExpirationDateTime"
                placeholder="MM/DD/YYYY HH:MM AM/PM"
              />
              <bit-hint>{{ "expirationDateDesc" | i18n }}</bit-hint>
            </bit-form-field>
          </div>
        </div>
        <div class="tw-flex">
          <bit-form-field class="tw-w-1/2 tw-pr-3">
            <bit-label for="maxAccessCount">{{ "maxAccessCount" | i18n }}</bit-label>
            <input bitInput type="number" formControlName="maxAccessCount" min="1" />
            <bit-hint>{{ "maxAccessCountDesc" | i18n }}</bit-hint>
          </bit-form-field>
          <bit-form-field class="tw-w-1/2 tw-pl-3" *ngIf="editMode">
            <bit-label for="accessCount">{{ "currentAccessCount" | i18n }}</bit-label>
            <input bitInput type="text" formControlName="accessCount" readonly />
          </bit-form-field>
        </div>
        <div class="tw-flex">
          <bit-form-field class="tw-w-1/2 tw-pr-3">
            <bit-label for="password" *ngIf="!hasPassword">{{ "password" | i18n }}</bit-label>
            <bit-label for="password" *ngIf="hasPassword">{{ "newPassword" | i18n }}</bit-label>

            <input bitInput type="password" formControlName="password" />
            <button type="button" bitIconButton bitSuffix bitPasswordInputToggle></button>
            <bit-hint>{{ "sendPasswordDesc" | i18n }}</bit-hint>
          </bit-form-field>
        </div>
        <bit-form-field>
          <bit-label>{{ "notes" | i18n }}</bit-label>
          <textarea bitInput formControlName="notes" rows="6"></textarea>
          <bit-hint>{{ "sendNotesDesc" | i18n }}</bit-hint>
        </bit-form-field>

        <bit-form-control>
          <input bitCheckbox type="checkbox" formControlName="hideEmail" />
          <bit-label>{{ "hideEmail" | i18n }}</bit-label>
        </bit-form-control>

        <bit-form-control>
          <input bitCheckbox type="checkbox" formControlName="disabled" />
          <bit-label>{{ "disableThisSend" | i18n }}</bit-label>
        </bit-form-control>
      </div>
    </span>
    <ng-container bitDialogFooter>
      <button
        type="submit"
        bitButton
        bitFormButton
        [appA11yTitle]="'save' | i18n"
        buttonType="primary"
      >
        {{ "save" | i18n }}
      </button>
      <button
        type="button"
        bitButton
        buttonType="secondary"
        [appA11yTitle]="'cancel' | i18n"
        bitDialogClose
      >
        {{ "cancel" | i18n }}
      </button>

      <button
        *ngIf="editMode"
        type="button"
        class="tw-ml-auto"
        bitIconButton="bwi-trash"
        buttonType="danger"
        [appA11yTitle]="'delete' | i18n"
        [bitAction]="deleteAndClose"
      ></button>
    </ng-container>
  </bit-dialog>
</form>
