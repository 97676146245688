<ng-container *ngIf="loading">
  <i
    class="bwi bwi-spinner bwi-spin text-muted"
    title="{{ 'loading' | i18n }}"
    aria-hidden="true"
  ></i>
  <span class="tw-sr-only">{{ "loading" | i18n }}</span>
</ng-container>
<div class="container page-content" *ngIf="!loading">
  <div class="page-header">
    <h1>{{ "setupProvider" | i18n }}</h1>
  </div>
  <p>{{ "setupProviderDesc" | i18n }}</p>
  <form [formGroup]="formGroup" [bitSubmit]="submit">
    <h2 class="mt-5">{{ "generalInformation" | i18n }}</h2>
    <div class="tw-grid tw-grid-flow-col tw-grid-cols-12 tw-gap-4">
      <div class="tw-col-span-6">
        <bit-form-field>
          <bit-label>{{ "providerName" | i18n }}</bit-label>
          <input type="text" bitInput formControlName="name" />
        </bit-form-field>
      </div>
      <div class="tw-col-span-6">
        <bit-form-field>
          <bit-label>{{ "billingEmail" | i18n }}</bit-label>
          <input type="email" bitInput formControlName="billingEmail" />
          <bit-hint *ngIf="enableConsolidatedBilling$ | async">{{
            "providerBillingEmailHint" | i18n
          }}</bit-hint>
        </bit-form-field>
      </div>
    </div>
    <app-manage-tax-information *ngIf="enableConsolidatedBilling$ | async" />
    <button bitButton bitFormButton buttonType="primary" type="submit">
      {{ "submit" | i18n }}
    </button>
  </form>
</div>
